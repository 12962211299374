import '../css/orders.css';
import useFetch from "../api/useFetch";
import usePost from '../api/usePost';
import OrderLine from './OrderLine'
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Updated from '../static/img/updated.png';
import MultiShipmentForm from './MultiShipmentForm';
import Loading from './Loading';

function Orders({ showFilters = true, status = 'all', suppliers= ['all'],  marketplaces= ['all'], notify}) {
    const [refresh, setRefresh] = useState(false);
    const [page, setPage] = useState(1);
    const [statusFilter, setStatusFilter] = useState(status);
    const [receptionTrackingNumber, setReceptionTrackingNumber] = useState('');
    const [selectedSuppliers, setSelectedSuppliers] = useState(suppliers);
    const [selectedMarketplaces, setSelectedMarketplaces] = useState(marketplaces);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [shipmentFormVisible, setShipmentFormVisible] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { postData } = usePost();
  
    const { result: orders, isLoading: ordersLoading, error: ordersError } = useFetch(`/orders?page=${page}${statusFilter === 'all' && (selectedSuppliers === ['all'] || selectedSuppliers.length === 0) && (selectedMarketplaces === ['all'] || selectedMarketplaces.length === 0)  ? '' : showFilters ? `&status=${statusFilter}&supplier=${selectedSuppliers}&marketplace=${selectedMarketplaces}` : `&status=${statusFilter}`}${receptionTrackingNumber ? `&reception_tracking_number=${receptionTrackingNumber}` : ''}`,[page, statusFilter, refresh]);
    const location = useLocation();

    useEffect(() => {
      setPage(1);
      setStatusFilter(status);
    }, [location, status]);

    const handleRefresh = () => {
      setRefresh(!refresh)
    }
    
    const handlePreviousClick = () => {
      if (page > 1) {
        setPage(page - 1);
      }
    };
  
    const handleNextClick = () => {
      if (page < orders.total / 15){
        setPage(page + 1);}
    };
  
    const handlePageClick = (pageNumber) => {
      setPage(pageNumber);
    };
  
    const renderPageNumbers = (pages) => {
      const totalPages = pages;
      const pageNumbers = [];
      const ellipsis = <div>...</div>;
  
      for (let i = 1; i <= totalPages; i++) {
        if (i === 1 || i === totalPages || (i >= page - 2 && i <= page + 2)) {
          pageNumbers.push(
            <div
              key={i}
              className={`number${page === i ? " active" : ""}`}
              onClick={() => handlePageClick(i)}
            >
              {i}
            </div>
          );
        } else if (pageNumbers[pageNumbers.length - 1] !== ellipsis) {
          pageNumbers.push(ellipsis);
        }
      }
  
      return pageNumbers;
    };
  
    const handleStatusFilterChange = (e) => {
      setStatusFilter(e.target.value);
      setPage(1)
    };

    const submitReceptionTrackingNumber = () => {
      setReceptionTrackingNumber(document.getElementById('reception-tracking-number').value);
      console.log(receptionTrackingNumber)
      setRefresh(!refresh)
      setPage(1)
    };

    const handleSupplierChange = (e) => {
      const value = e.target.value;
      const isChecked = e.target.checked;
    
      setSelectedSuppliers((prevSelectedSuppliers) => {
        if (isChecked) {
          return [...prevSelectedSuppliers, value];
        } else {
          return prevSelectedSuppliers.filter((supplier) => supplier !== value);
        }
      });
    };

    const handleMarketplaceChange = (e) => {
      const value = e.target.value;
      const isChecked = e.target.checked;
    
      setSelectedMarketplaces((prevSelectedMarketplaces) => {
        if (isChecked) {
          return [...prevSelectedMarketplaces, value];
        } else {
          return prevSelectedMarketplaces.filter((marketplace) => marketplace !== value);
        }
      });
    };
  
    const handleAllSuppliersChange = (event) => {
      const isChecked = event.target.checked;
      setSelectedSuppliers(isChecked ? ['all'] : []);
    };

    const handleAllMarketplacesChange = (event) => {
      const isChecked = event.target.checked;
      setSelectedMarketplaces(isChecked ? ['all'] : []);
    };


    const INTERNAL_STATUS = [    ['all', 'All'],
    ['to_buy', 'Placed By Client - To Buy'],
    ['pending_sourcing', 'Sourcing Issue'],
    ['pending_purchase_shipment', 'Pending Purchase Shipment'],
    ['pending_purchase_reception', 'Pending Purchase Reception'],
    ['to_pickup','To Pick Up'],
    ['received', 'Received'],
    ['partly_received', 'Partly Received'],
    ['shipped', 'Shipped'],
    ['anticipated_shipping','Pre-Shipped'],
    ['dummy_shipping','Dummy-Shipped'],
    ['received_by_client', 'Received by Client'],
    ['canceled_by_client', 'Canceled by Client'],
    ['canceled_by_us', 'Canceled - No Sourcing'],
    ['return_received', 'Return Received'],
    ['dummy_shipping_kept', 'Dummy-Shipped - Kept in Inventory'],
    ['archived', 'Archived']
  ];

  const SUPPLIERS = [ [1, 'amazon.com'],
  [2, 'amazon.ca'],
  [3, 'lireka.com'],
  [4, 'librairiemartelle.com'],
  [5, 'amazon.fr'],
  [7,'Other - USA'],
  [9, 'Other - FR'],
  [10, 'decitre.fr'],
  [14, 'Ingram'],
  ]

  const MARKETPLACES = [ [1, 'amazon.com'],
  [2, 'amazon.ca'],
  [5, 'amazon.fr'],
  [13, 'amazon.com.mx'],
  [17, 'amazon.co.uk'],
  [18, 'amazon.com.br']
  ]

  const openShipmentForm = () => {
    setShipmentFormVisible(true);
  };

  const selectAll = (orders) => {
    const orderIds = orders.items.map((order) => order.id);
    setSelectedOrders([...selectedOrders, ...orderIds]);
  }
  
  const feedbackRequested = async () =>  {
    const response = await postData('/mark_feedback_requested', selectedOrders);
    if (response) {
      setRefresh(!refresh)
      setSelectedOrders([])
      notify('Feedback Requested Updated')
    }
  };

  const markReceived = async () =>  {
  const response = await postData('/mark_received_multi', {order_ids: selectedOrders});
  if (response) {
    setRefresh(!refresh)
    setSelectedOrders([])
    notify('Orders Mark as Received')
  }
};

  return (
    <>
      <div div className="orders">
        <div div className="title">
          ORDERS
          <i className="fas fa-sync" onClick={handleRefresh}></i>
        </div>
        {showFilters && (
          <div div className="filters">
            <div>
            <div className="filter-title"><label htmlFor="status-filter">Status</label></div>
            <select id="status-filter" value={statusFilter} onChange={handleStatusFilterChange}>
              {INTERNAL_STATUS.map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            </div>
          <div className="filter-title">
          <label>Suppliers:</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="supplier-all"
              value="all"
              checked={selectedSuppliers.includes("all")}
              onChange={handleAllSuppliersChange}
            />
            <label htmlFor="supplier-all">All</label>
            </div>
            {selectedSuppliers.includes("all") || (
            <div className='supplier-labels'>
          {SUPPLIERS.map(([value, label]) => (
            <div key={value}>
              <input
                type="checkbox"
                id={`supplier-${value}`}
                value={value}
                checked={selectedSuppliers.includes(value.toString())}
                onChange={handleSupplierChange}
              />
              <label class='supplier-label' htmlFor={`supplier-${value}`}>{label}</label>
            </div>
          ))}
              </div>)}
          <div className="filter-title">
          <label>Marketplaces:</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="supplier-all"
              value="all"
              checked={selectedMarketplaces.includes("all")}
              onChange={handleAllMarketplacesChange}
            />
            <label htmlFor="supplier-all">All</label>
            </div>
            {selectedMarketplaces.includes("all") || (
            <div className='supplier-labels'>
          {MARKETPLACES.map(([value, label]) => (
            <div key={value}>
              <input
                type="checkbox"
                id={`supplier-${value}`}
                value={value}
                checked={selectedMarketplaces.includes(value.toString())}
                onChange={handleMarketplaceChange}
              />
              <label class='supplier-label' htmlFor={`supplier-${value}`}>{label}</label>
            </div>
          ))}
          </div>)}
          <br/>
          <div>
            <input
              type="text"
              placeholder='Reception Tracking Number'
              id="reception-tracking-number"
            /><i style={{marginLeft: '10px', cursor: 'pointer'}} className="fas fa-filter" onClick={() => submitReceptionTrackingNumber()}></i>
            </div>
              </div>
        )}
        <div className='orders-select'>
        {orders && selectedOrders.length < 15 && showFilters && (<div className='group-selected-button' onClick={() => selectAll(orders)}>Select All</div>)}
        {showFilters && selectedOrders.length > 0 && (<><div className='group-selected-button' onClick={openShipmentForm}>Ship Orders</div><div className='group-selected-button' onClick={markReceived}>Mark as Received</div></>)}
        {status === 'feedback_to_request' && selectedOrders.length > 0 && (<div className='group-selected-button' onClick={feedbackRequested}>FB Requested</div>)}
        {selectedOrders.length > 0 && (<div className='selected-orders'>{selectedOrders.length} order(s) selected   <i class="fas fa-times" onClick={() => setSelectedOrders([])}></i></div>)}
        </div>
        <div className="table">
          <div className="table-header">
            <div className="domain">MarketPlace</div>
            <div className="date">Order Date</div>
            <div className="order_id">Order Id</div>
            <div className="product">Product</div>
            <div className="sourcing">Sourcing</div>
            <div className="destination">Destination</div>
            <div className="price">Price</div>
            <div className="status">Status</div>
            <div className="status">Ext Status</div>
            <div className="action">Details</div>
            {(showFilters || status === 'feedback_to_request') && (<div className="action">Select</div>)}
          </div>
          <div className="table-body">
            {ordersLoading && <div div className='table-placeholder'><Loading /></div>}
            {ordersError && <div>{ordersError}</div>}
            {!ordersLoading &&
              orders && orders.total > 0 &&
              orders.items.map((order) => (
                <OrderLine
                  has_checkbox = {showFilters || status === 'feedback_to_request'}
                  key={order.id}
                  id={order.id}
                  marketplace_domain={order.marketplace && order.marketplace.domain}
                  marketplace_api_id={order.marketplace && order.marketplace.api_id}
                  order_date={order.purchase_date}
                  order_id={order.marketplace_order_id}
                  product_name={
                    order.is_multiproducts 
                      ? "Multiproducts"
                      : 
                       order.products.length === 0 ?
                       "Unknown"
                       :
                      order.products[0].product.name.length > 50
                      ? order.products[0].product.name.substring(0, 45).concat(" (...)")
                      : order.products[0].product.name
                  }
                  product_id={
                    (order.is_multiproducts || order.products.length === 0)
                      ? null
                      : order.products[0].product.id
                  }
                  product_sourcing_marketplace={
                    order.sourcing_marketplace ? order.sourcing_marketplace.name : "Unknown"
                  }
                  detination_country={order.destination_country}
                  net_revenue={order.net_revenue_usd}
                  internal_status={order.internal_status}
                  marketplace_status={order.marketplace_status}
                  selectedOrders={selectedOrders}
                  setSelectedOrders={setSelectedOrders}
                  feedback={status === 'feedback_to_request'}
                />
              ))}
                        {!ordersLoading &&
              orders && orders.total === 0 &&
              <img src={Updated} alt="" width="200px" id='updated'/>
              }
          </div>
        </div>
      </div>
      {!ordersLoading && orders && orders.total > 15 && (
        <div div className="pagination">
          <div div className="number" onClick={handlePreviousClick}>
            <i className="fas fa-angle-left"></i>
          </div>
          {renderPageNumbers(parseInt(orders.total / 15) + 1)}
          <div div className="number" onClick={handleNextClick}>
            <i className="fas fa-angle-right"></i>
          </div>
        </div>
      )}
      {shipmentFormVisible && (<><MultiShipmentForm notify={notify}  visible={shipmentFormVisible} setVisible={setShipmentFormVisible}  setFormSubmitted={setFormSubmitted} formSubmitted={formSubmitted} isMulti={true} selectedOrders={selectedOrders} setSelectedOrders={setSelectedOrders}/><div div className="bg-disabled"></div></>)}

    </>
  );  
}

export default Orders