import usePost from '../api/usePost';
import { useState, useContext } from 'react';
import {NotificationsContext} from '../context/NotificationsContext';

function PurchaseForm({notify, visible, setVisible, order, setFormSubmitted, formSubmitted}) {
    const { postData, isLoading, error } = usePost();
    const currentDate = new Date().toISOString().split('T')[0];
    let is_edit = order.purchases.length > 0 
    let marketplace = is_edit  ? order.purchases[0].marketplace.id : '4'
    let supplier_id = is_edit  ? order.purchases[0].supplier_id : ''
    let price = is_edit  ? order.purchases[0].price : ''
    let currency = is_edit  ? order.purchases[0].currency : 'EUR'
    let dropshipped = is_edit  ? order.is_dropshipped : false
    const [formData, setFormData] = useState({marketplace: marketplace, supplier_id: supplier_id, price: price, currency: currency, dropshipped: dropshipped, order_id: order.id, status:'pending_shipment', date:currentDate, is_edit: is_edit});
    const { NotificationRefresh, setNotificationRefresh } = useContext(NotificationsContext);
    
    const handleClose = () => {
        setVisible(false);
      };

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData)
        const response = await postData('/create_purchase', formData);
        if (response) {
            setFormSubmitted(!formSubmitted);
            setVisible(false);
            setNotificationRefresh(!NotificationRefresh)
            if (is_edit){
              notify('Purchase Edited Succesfully')}
            else {
                notify('Purchase Created Succesfully')}
        }
      };
  return (
    visible && (
    <div id="purchase-form">
    <i id="close-purchase-form" className="fas fa-times" onClick={handleClose}></i>
    <form onSubmit={handleSubmit}>
        <input type="hidden" id="order-id" name="order_id" value={formData.order_id} />
      <div>
        <label htmlFor="purchase-site">
          <i className="fas fa-store"></i>Purchase Site:
        </label>
        <select id="purchase-site" name="marketplace" value={formData.marketplace} onChange={handleChange}>
            <option value="4">Martelle</option>
            <option value="14">Ingram</option>
            <option value="1">Amazon USA</option>
            <option value="5">Amazon France</option>
            <option value="10">Decitre</option>
            <option value="9">Other FR</option>
            <option value="7">Other USA</option>
            <option value="12">Inventory</option>
      </select>
      </div>
      <div>
        <label htmlFor="purchase-order-id">
          <i className="fas fa-tag"></i>Purchase Order ID:
        </label>
        <input type="text" id="purchase-order-id" name="supplier_id" value={formData.supplier_id} onChange={handleChange}/>
      </div>
      <div>
        <label htmlFor="purchase-price">
          <i className="fas fa-tags"></i>Purchase Price:
        </label>
        <input type="text" id="purchase-price" name="price" value={formData.price} onChange={handleChange}/>
      </div>
      <div>
        <label htmlFor="purchase-currency">
          <i className="fas fa-coins"></i>Purchase Currency:
        </label>
        <select id="purchase-currency" name="currency" value={formData.currency} onChange={handleChange}>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="JPY">JPY</option>
          <option value="GBP">GBP</option>
          <option value="AUD">AUD</option>
        </select>
      </div>
      <div>
        <label htmlFor="dropshipped">Dropshipped</label>
        <input
          type="checkbox"
          id="dropshipped"
          name="dropshipped" 
          value={formData.dropshipped}
          checked={formData.dropshipped}
          onChange={handleChange}
         />
      </div>
        <button type="submit">
          <i className="fas fa-save" disabled={isLoading}></i>Save
        </button>
    </form>
    {error && <p>Error: {error}</p>}
  </div>)
  )
}

export default PurchaseForm