import '../css/order.css';
import useFetch from '../api/useFetch';
import usePost from '../api/usePost';
import { useParams } from 'react-router-dom';
import PurchaseForm from './PurchaseForm';
import ShipmentForm from './ShipmentForm'; 
import CustomerShipmentForm from './CustomerShipmentForm';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import TransactionLine from './TransactionLine'
import Loading from './Loading';
import OrderUpdateForm from './OrderUpdateForm';
import FeedbackForm from './FeedbackForm'
import CommentForm from './CommentForm';
import InventoryForm from './InventoryForm'

const Order = ({ notify }) => {
    const { id } = useParams();
    const { postData, error } = usePost();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { result: order, isLoading: orderLoading, error: orderError } = useFetch(`/order/${id}`,formSubmitted);
    const [purchaseFormVisible, setPurchaseFormVisible] = useState(false);
    const [shipmentFormVisible, setShipmentFormVisible] = useState(false);
    const [orderUpdateFormVisible, setOrderUpdateFormVisible] = useState(false);
    const [FeedbackFormVisible, setFeedbackFormVisible] = useState(false);
    const [CustomerShipmentFormVisible, setCustomerShipmentFormVisible] = useState(false);
    const [CommentFormVisible, setCommentFormVisible] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(0);
    const [inventoryFormVisible, setInventoryFormVisible] = useState(false);
    const [View, setView] = useState('general')
    const [loadingShipment, setLoadingShipment] = useState(false)
    
    const copyClick = (event) => {
        const content = event.target.textContent;
        navigator.clipboard.writeText(content);
        notify('Data Copied')
      };

      const openPurchaseForm = () => {
        setPurchaseFormVisible(true);
      };

      const openShipmentForm = () => {
        setShipmentFormVisible(true);
      };

      const openFeedbackForm = () => {
        setFeedbackFormVisible(true);
      };

    //   const openCutomerShipmentForm = () => {
    //     setCustomerShipmentFormVisible(true)
    //   }

      const openOrderUpdateForm = () => {
        setOrderUpdateFormVisible(true)
      }

      const openInventoryForm = () => {
        setInventoryFormVisible(true)
      }

      const openCommentForm = () => {
        setCommentFormVisible(true)
      }

      const markActionDone = async () => {
        const data = {order_id: order.id}
        const response = await postData('/mark_action_done', data);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Action MArked as Done')
        }      }

      const sourcingIssue = async () => {
        const data = {order_id: order.id}
        const response = await postData('/sourcing_issue', data);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Sourcing Issue Saved')
        }
      }

      const cancelPurchase = async () => {
        const data = {purchase_id: order.purchases[0].id}
        const response = await postData('/cancel_purchase', data);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Purchase Canceled')
        }
      }

      const markReceived = async () => {
        const data = {order_id: order.id}
        const response = await postData('/mark_received', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Order Marked as Received')
        }
      }

      const markPartlyReceived = async () => {
        const data = {order_id: order.id}
        const response = await postData('/mark_partly_received', data);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Order Marked Partly Received')}
      };

      const markReturnReceived = async (sellable) => {
        const data = {order_id: order.id, sellable: sellable}
        const response = await postData('/mark_return_received', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Order Return Marked as Received')
        }
      }

      const markAZClaim = async () => {
        const data = {order_id: order.id}
        const response = await postData('/mark_az_claim', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Order Marked as AZ Claim')
        }
      }

      const removeAZClaim = async () => {
        const data = {order_id: order.id}
        const response = await postData('/remove_az_claim', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Order removed as AZ Claim')
        }
      }

      const removeFeedback = async () => {
        const data = {order_id: order.id}
        const response = await postData('/remove_feedback', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Feedback removed')
        }
      }

      
      const createQuickPurchase = async () => {
        const data = {order_id: order.id, marketplace: order.sourcing_marketplace.id}
        const response = await postData('/create_purchase', data);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Quick Purchase Created Succesfully')}
      };


      const create_easyship_shipment = async () => {
        const data = {order_id: order.id}
        setLoadingShipment(true)
        const response = await postData('/create_easyship_shipment', data);
        if (response && !Object.hasOwn(response, 'error')) {
            notify('Easyship shipment created')
            setFormSubmitted(!formSubmitted);
            setLoadingShipment(false)
        }
        if (Object.hasOwn(response, 'error')) {
            notify(response.error,"error")
            setLoadingShipment(false)
        }
      }

      const markShipped = async () => {
        const data = {order_id: order.id}
        const response = await postData('/mark_shipped', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Order Marked as Shipped')
        }
      }

      const markAnswered = async () => {
        const data = {order_id: order.id}
        const response = await postData('/mark_answered', data);
        console.log('Response:', response);
        console.log('Error:', error);
        if (response) {
            setFormSubmitted(!formSubmitted);
            notify('Order Requests Marked as responded')
        }
      }

      const copyConversation = () => {
        navigator.clipboard.writeText(order.messages.map(message => `${message.sender}: ${message.content}`).join('\n'));
        notify('Conversation copied')
      }

      const handleProductClick = (index) => {
        setCurrentProduct(index);
      }

      const handleViewClick = (view) => {
        setView(view)
      }


      const INTERNAL_STATUS = {
        to_buy: "Placed By Client - To Buy",
        pending_sourcing: "Pending - Sourcing Issue",
        pending_purchase_shipment: "Pending Purchase Shipment",
        pending_purchase_reception: "Pending Purchase Reception",
        to_pickup: "To Pick Up",
        received: "Received",
        anticipated_shipping: "Pre-Shipped",
        dummy_shipping: "Dummy-Shipped - Sourcing Issue",
        shipped: "Shipped",
        received_by_client: "Received by Client",
        canceled_by_client: "Canceled by Client",
        canceled_by_us: "Canceled - No Sourcing",
        canceled_by_marketplace: "Canceled by Marketplace",
        dummy_shipping_kept: "Dummy-Shipped - Kept in Inventory",
        return_received: "Return Received",
        archived: "Archived",
        partly_received: "Partly Received"
      };
  return (
    <>
    {orderLoading && <><Loading/></>}
    {orderError && <div>{orderError}</div>}
    {order && !orderLoading && (<>
        {order.comment && <div className="order-comment" onClick={() => handleViewClick('extra')}><i className="fas fa-exclamation-triangle"></i>{`This order has ${order.has_action ? 'an action' : 'a comment'}`}</div>}
        {order.purchases.length === 0 && order.products[currentProduct].product.has_inventory && <div className="order-comment"><i className="fas fa-exclamation-triangle"></i>Product available in Inventory</div>}
        <div class="main-infos">
            <div class={`main-info ${View === 'general' ? 'active' : ''}`} onClick={() => handleViewClick('general')}>
            <i className="fas fa-info-circle"></i>General Info
            </div>
            {order.return_requested && (
            <div class={`main-info ${View === 'return' ? 'active' : ''}`} onClick={() => handleViewClick('return')}>
            <i className="fas fa-sync"></i>Return
            </div>)}
            {order.transactions.length > 0 && (
            <div class={`main-info ${View === 'finance' ? 'active' : ''}`} onClick={() => handleViewClick('finance')}>
            <i className="fas fa-cash-register"></i>Finance
            </div>)}
            {order.messages.length > 0 && (
            <div class={`main-info ${View === 'messages' ? 'active' : ''}`} onClick={() => handleViewClick('messages')}>
            <i className="fas fa-comments"></i>Messages
            </div>)}
            <div class={`main-info ${View === 'extra' ? 'active' : ''}`} onClick={() => handleViewClick('extra')}>
            <i className="fas fa-info"></i>Extra Info
            </div>
        </div>
        {View === 'general' && (
        <div className="order-infos">
            <div className="order-info">
                <div className="order-info-line">
                    <h2><i className="fas fa-barcode"></i>Internal Status</h2>
                    <h3>{INTERNAL_STATUS[order.internal_status]}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-barcode"></i>Order ID</h2>
                    <h3 onClick = {copyClick} className="copy-click">{order.marketplace_order_id}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-shopping-basket"></i>Marketplace</h2>
                    <h3>{order.marketplace.domain}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-calendar-day"></i>Date</h2>
                    <h3>{order.purchase_date}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-hashtag"></i>Number of products</h2>
                    <h3>{order.nb_products}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-coins"></i>Currency</h2>
                    <h3>{order.currency}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-info"></i>Marketplace Status</h2>
                    <h3>{order.marketplace_status}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-money-check"></i>Client Payment</h2>
                    <h3>{order.client_payment}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-toolbox"></i>Tax</h2>
                    <h3>{order.tax}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-toolbox"></i>Amazon Fee</h2>
                    <h3>{order.marketplace_fee}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-cash-register"></i>Received</h2>
                    <h3>{order.net_revenue}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-dollar-sign"></i>Received - USD</h2>
                    <h3>{order.net_revenue_usd}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-user"></i>Client</h2>
                    <h3>{order.client_name}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-globe"></i>Destination Country</h2>
                    <h3>{order.destination_country}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-map"></i>Destination Province / State</h2>
                    <h3>{order.destination_region}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-city"></i>Destination City</h2>
                    <h3>{order.destination_city}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-map-marker"></i>Destination Postal Code</h2>
                    <h3>{order.destination_postal_code}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-map-pin"></i>Destination Address</h2>
                    <h3>{order.destination_address}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-clock"></i>Latest Shipping Date</h2>
                    <h3>{order.latest_ship_date}</h3>
                </div>
                <div className="order-info-line">
                    <h2><i className="fas fa-clock"></i>Latest Delivery Date</h2>
                    <h3>{order.latest_delivery_date}</h3>
                </div>
                <button id="purchase-form-button" onClick={openOrderUpdateForm}><i className="fas fa-sync" style={{color: "orange"}}></i>Update Internal Info</button>
            </div>
            <div className="product-info">
                {order.products.length > 1 &&
                <div className="tabs">
                {Array.from({ length: order.products.length }, (_, index) => (
                <div
                    key={index}
                    className={`tab${index === (currentProduct) ? " active-tab" : ""}`}
                    onClick = {index === (currentProduct) ? "" : () => handleProductClick(index)}
                >
                    {`Product #${index + 1}`}
                </div>
                ))}
                </div>}
                <div className="product-header">
                    <div className="product-details">
                        <div className="product-info-line">
                            <h2><i className="fas fa-book"></i>Title</h2>
                            <h3 onClick = {copyClick} className="copy-click">{order.products.length > 1 ? order.products[currentProduct].product.name : order.products[0].product.name}</h3>
                        </div>
                        <div className="product-info-line">
                            <h2><i className="fas fa-barcode"></i>ASIN</h2>
                            <h3 onClick = {copyClick} className="copy-click">{order.products.length > 1 ? order.products[currentProduct].product.asin : order.products[0].product.asin}</h3>
                        </div>
                        <div className="product-info-line">
                            <h2><i className="fas fa-barcode"></i>EAN</h2>
                            <h3 onClick = {copyClick} className="copy-click">{order.products.length > 1 ? order.products[currentProduct].product.ean : order.products[0].product.ean}</h3>
                        </div>
                        <div className="product-info-line">
                            <h2><i className="fas fa-euro-sign"></i>Sourcing Price</h2>
                            <h3  onClick = {copyClick} className="copy-click">{order.products.length > 1 ? order.products[currentProduct].product.sourcing_price : order.products[0].product.sourcing_price}</h3>
                        </div>
                        <div className="product-info-line">
                            <h2><i className="fas fa-hashtag"></i>Quantity</h2>
                            <h3  onClick = {copyClick} className="copy-click">{order.products.length > 1 ? order.products[currentProduct].quantity : order.products[0].quantity}</h3>
                        </div>
                        <div className="product-info-line">
                            <h2><i className="fas fa-weight-hanging"></i>Weight</h2>
                            <h3>{order.products.length > 1 ? order.products[currentProduct].product.weight * 1000 : order.products[0].product.weight * 1000} g / {(order.products.length > 1 ? order.products[currentProduct].product.weight * 2.20462 : order.products[0].product.weight * 2.20462).toFixed(2)} lbs</h3>
                        </div>
                        <div className="product-info-line">
                            <h2><i className="fas fa-box"></i>Supplier(s)</h2>
                            <h3>{order.products.length === 1 ? order.products[0].product.suppliers.map(supplier => supplier.name).join(', ') : order.products.length > 0 && order.products[currentProduct].product.suppliers.map(supplier => supplier.name).join(', ')}</h3>
                        </div>
                        <div className="product-info-line">
                            <h2><i className="fas fa-link"></i>Martelle link</h2>
                            <h3><Link to={`https://www.librairiemartelle.com/listeliv.php?base=all&mots_recherche=${order.products.length > 1 ? order.products[currentProduct].product.ean : order.products[0].product.ean}`}  target="_blank"><i class="fas fa-link"></i></Link></h3>
                        </div>
                        <div class="product-info-line">
                            <h2><i class="fas fa-link"></i>Amazon link</h2>
                            <h3><Link to={`https://www.amazon.com/dp/${order.products.length > 1 ? order.products[currentProduct].product.asin : order.products[0].product.asin}/`} target="_blank"><i class="fas fa-link"></i></Link></h3>
                        </div>
                    </div>
                    <div className="product-image">
                        <Link to={`/product/${order.products.length > 1 ? order.products[currentProduct].product.id : order.products[0].product.id}`}>
                        <img src={order.products.length > 1 ? order.products[currentProduct].product.image_url : order.products[0].product.image_url} alt={order.products.length > 1 ? order.products[currentProduct].product.name : order.products[0].product.name}/>
                        </Link>
                    </div> 
                </div>
                { order.purchases.length > 0 && (
                <>
                <div className="product-info-line">
                    <h2><i className="fas fa-store"></i>Purchase Site</h2>
                    <h3>{order.purchases.length === 1 && order.purchases[0].marketplace.domain}</h3>
                </div>
                <div className="product-info-line">
                    <h2><i className="fas fa-barcode"></i>Purchase Order ID</h2>
                    <h3 onClick = {copyClick} className="copy-click">{order.purchases.length === 1 && order.purchases[0].supplier_id}</h3>
                </div>
                <div className="product-info-line">
                    <h2><i className="fas fa-tags"></i>Purchase Price</h2>
                    <h3>{order.purchases.length === 1 && order.purchases[0].price} {order.purchases.length === 1 && order.purchases[0].currency}</h3>
                </div>
                <div className="product-info-line">
                    <h2><i className="fas fa-calendar-day"></i>Purchase Date</h2>
                    <h3>{order.purchases.length === 1 && order.purchases[0].date}</h3>
                </div>
                <div className="product-info-line">
                    <h2><i className="fas fa-dollar-sign"></i>Purchase Price USD</h2>
                    <h3>{order.purchases.length === 1 && order.purchases[0].usd_price} USD</h3>
                </div>
                <div className="buttons">
                    <button id="purchase-form-button" onClick={openPurchaseForm}><i className="fas fa-shopping-cart"></i>Edit Purchase</button>
                    <button id="purchase-form-button" onClick={cancelPurchase}><i className="fas fa-trash"></i>Cancel Purchase</button>
                    {order.has_canceled_purchase && (<button id="purchase-form-button"><i className="fas fa-window-close" style={{color: "red"}}></i>See Cancelled Purchase(s)</button>)}
                    { (order.purchases.length > 0 && order.purchases[0].shipment === null) && (
                    <button id="shipment-form-button"  onClick={openShipmentForm}><i className="fas fa-plane-departure"></i>Create Shipment</button>
                )}
                </div></>)}
                { order.purchases.length === 0 && (
                <><div className="create-button">
                {order.sourcing_marketplace && <button id="purchase-form-button" onClick={createQuickPurchase}><i className="fas fa-bolt"></i>Quick Purchase</button>}
                <button id="purchase-form-button" onClick={openPurchaseForm}><i className="fas fa-shopping-cart"></i>Create Purchase</button>
                {order.has_canceled_purchase && (<button id="purchase-form-button"><i className="fas fa-window-close" style={{color: "red"}}></i>See Cancelled Purchase(s)</button>)}
                <button id="purchase-form-button" onClick={sourcingIssue}><i className="fas fa-question" style={{color: "orange"}}></i>Sourcing Issue</button>
                </div></>)}
                { order.purchases.length > 0 && order.purchases[0].shipment && (
                <>
                <div className="product-info-line">
                    <h2><i className="fas fa-plane-departure"></i>Purchase Carrier</h2>
                    <h3>{order.purchases[0].shipment.carrier}</h3>
                </div>
                <div className="product-info-line">
                    <h2><i className="fas fa-barcode"></i>Purchase Tracking Number</h2>
                    <h3 onClick = {copyClick} className="copy-click">{order.purchases[0].shipment.tracking_number}</h3>
                </div>
                <div className="product-info-line">
                    <h2><i className="fas fa-tachometer-alt"></i>Purchase Tracking Status</h2>
                    <h3>{order.purchases[0].shipment.status}</h3>
                </div>
                <div className="product-info-line">
                    <h2><i className="fas fa-link"></i>Purchase Tracking Link</h2>
                    <h3><Link to={order.purchases[0].shipment.tracking_link} target="_blank">open</Link></h3>
                </div>
                {order.purchase_shipment_cost > 0 &&(
                <div className="product-info-line">
                    <h2><i className="fas fa-dollar-sign"></i>Purchase Shipping Cost</h2>
                    <h3>{order.purchase_shipment_cost} USD</h3>
                </div>)}
                {(order.purchases[0].shipment.actual_delivery_date || order.purchases[0].shipment.expected_delivery_date_max) && 
                (<>
                <div className="product-info-line">
                    <h2><i className="fas fa-calendar-check"></i>{(order.purchases[0].shipment.actual_delivery_date && "Actual Reception") || "Actual Reception"}</h2>
                    <h3>{order.purchases[0].shipment.actual_delivery_date || order.purchases[0].shipment.expected_delivery_date_max || "unknown"}</h3>
                </div> 
                </>)}
                <div className="buttons">
                <button id="shipment-form-button"  onClick={openShipmentForm}><i className="fas fa-plane-departure"></i>Edit Shipment</button>
                { !order.is_received && (<button onClick={markReceived}><i className="fas fa-hand-holding"></i>Mark as Received</button>) } 
                { !order.is_received && order.is_multiproducts && (<button onClick={markPartlyReceived}><i className="fas fa-hand-holding"></i>Mark as Partly Received</button>) } 
                <button onClick={openInventoryForm}><i className="fas fa-warehouse"></i>Place Product in Inventory</button>
                </div>
                </>)}           
            </div>
            <div className="summary-container">
                {order.shipments.length === 1 && order.shipments[0].tracking_number &&
                (<div className="shipping-info">
                    <div className="shipping-info-line">
                        <h2><i className="fas fa-plane-departure"></i>Carrier | Service</h2>
                        <h3>{order.shipments[0].carrier} | {order.shipments[0].carrier_service}</h3>
                    </div>
                    <div className="shipping-info-line">
                        <h2><i className="fas fa-barcode"></i>Tracking Number</h2>
                        <h3 onClick = {copyClick} className="copy-click">{order.shipments[0].tracking_number}</h3>
                    </div>
                    <div className="shipping-info-line">
                        <h2><i className="fas fa-tachometer-alt"></i>Tracking Status</h2>
                        <h3>{order.shipments[0].status}</h3>
                    </div>
                    <div className="shipping-info-line">
                        <h2><i className="fas fa-link"></i>Tracking Link</h2>
                        <h3><Link to={order.shipments[0].tracking_link} target="_blank">open</Link></h3>
                    </div>
                    <div className="shipping-info-line">
                        <h2><i className="fas fa-calendar-check"></i>Expected Delivery</h2>
                        <h3>{order.latest_delivery_date}</h3>
                    </div>
                    <div className="shipping-info-line">
                        <h2><i className="fas fa-calendar-check"></i>Actual Delivery</h2>
                        <h3>{order.client_delivery_date}</h3>
                    </div>
                    <div className="shipping-info-line">
                        <h2><i className="fas fa-shopping-basket"></i>Shipping Cost (USD)</h2>
                        <h3>{order.shipments[0].cost_usd}</h3>
                    </div>
                    <div className="shipping-info-line">
                        <h2><i className="fas fa-money-check"></i>Total Shipment Cost - USD</h2>
                        <h3>{order.shipments[0].cost_usd}</h3>
                    </div>
                    <div className="buttons">
                        {order.shipments.length > 0 && order.is_received && !order.is_shipped && <button onClick={markShipped}><i className="fas fa-plane-departure"></i>Mark Shipped</button>}
                        {order.shipments.length > 0 && order.shipments[0].label_url && <Link to={order.shipments[0].label_url} target="_blank"><button><i className="fas fa-barcode"></i>Print Label</button></Link>}
                        {order.shipments.length > 0 && order.shipments[0].invoice_url && <Link to={order.shipments[0].invoice_url} target="_blank"><button><i className="fas fa-barcode"></i>Print Invoice</button></Link>}
                    </div>
                </div>)}
                {order.is_dropshipped &&
                (<div className="shipping-info">
                <div className="buttons unique">
                        <button className='green'><i className="fas fa-plane-departure"></i>Order Dropshipped</button>
                </div>
                </div>)}
                {order.marketplace_status !== 'Canceled' && order.shipments.length === 0  && !order.is_dropshipped && !loadingShipment &&
                (<div className="shipping-info">
                <div className="buttons unique">
                        <button onClick={create_easyship_shipment}><i className="fas fa-plane-departure"></i>Create Easyship Shipment</button>
                </div>
                </div>)}
                {order.marketplace_status === 'Canceled' && order.shipments.length === 0  && !order.is_dropshipped && !loadingShipment &&
                (<div className="shipping-info">
                <div className="buttons unique">
                        <button className='red'><i className="fas fa-plane-departure"></i>Do Not Ship - Order Canceled</button>
                </div>
                </div>)}
                {order.shipments.length === 0  && !order.is_dropshipped && loadingShipment &&
                (<div className="shipping-info">
                <div className="buttons unique">
                        <button><i className="fas fa-plane-departure"></i>In Process...</button>
                </div>
                </div>)}
                {/* {(order.marketplace.domain !== 'amazon.com' && order.shipments.length === 0 && !order.is_dropshipped && !loadingShipment) &&
                (<div className="shipping-info">
                <div className="buttons unique">
                        <button onClick={openCutomerShipmentForm}><i className="fas fa-plane-departure"></i>Create Shipment</button>
                </div>
                </div>)}
                 */}
                <div className="summary-info">
                    <div className="summary-info-line">
                        <h2><i className="fas fa-tag"></i>Revenue (Theoritical) - USD</h2>
                        <h3>${order.net_revenue_usd} (${order.expected_net_revenue_usd})</h3>
                    </div>
                    <div className="summary-info-line">
                        <h2><i className="fas fa-tag"></i>Purchase Cost (Theoritical) - USD</h2>
                        <h3>${order.actual_purchase_cost} (${order.theoritical_purchase_cost})</h3>
                    </div>
                    <div className="summary-info-line">
                        <h2><i className="fas fa-tag"></i>Total Supply Cost (Theoritical) - USD</h2>
                        <h3>${order.actual_supply_cost} (${order.theoritical_supply_cost})</h3>
                    </div>
                    <div className="summary-info-line">
                        <h2><i className="fas fa-tag"></i>Total Workforce Cost (Theoritical) - USD</h2>
                        <h3>${order.actual_workforce_cost} (${order.theoritical_workforce_cost})</h3>
                    </div>
                    <div className="summary-info-line">
                        <h2><i className="fas fa-tag"></i>Total Shipment Cost (Theoritical) - USD</h2>
                        <h3>${order.actual_shipment_cost} (${order.theoritical_shipment_cost})</h3>
                    </div>
                    <div className="summary-info-line">
                        <h2><i className="fas fa-tag"></i>Profit (Theoritical) - USD</h2>
                        <h3>${order.actual_net_profit} (${order.expected_net_profit})</h3>
                    </div>
                    <div className="summary-info-line">
                        <h2><i className="fas fa-shopping-basket"></i>Profit (Theoritical) - %</h2>
                        <h3>12% (11.5%)</h3>
                    </div>
                </div>
                <div className="buttons">
                    {order.has_az_claim ? <button onClick={removeAZClaim} className='red'><i className="fas fa-exclamation-triangle"></i>Remove AZ Claim</button> : <button onClick={markAZClaim}><i className="fas fa-exclamation-triangle"></i>Add AZ Claim</button>}
                    {!order.feedback_rating ? <button onClick={openFeedbackForm}><i className="fas fa-star"></i>Add FB Rating</button> : <button>{[...Array(order.feedback_rating)].map((_, index) => (<i key={index} className={`fas fa-star ${order.feedback_rating < 3 ? 'red' : (order.feedback_rating > 3 ? 'green' : 'yellow')}`}></i>))}<i className="fas fa-comment-alt" title={order.feedback_comment}></i><i onClick={removeFeedback} className="fas fa-trash"></i></button>}
                </div>
            </div>
        </div>)}
        </>)}
        {View === 'return' &&(
            <>
            {!order.return_requested && "No Return Request"}
            {!order.return_received && (
                <>
                <div className='main-infos'>
                        {order.return_status === 'pending_reception' &&
                            <div className='main-info active small'>
                                <i className="fas fa-clock"></i>Pending Reception
                            </div>
                        }
                        {order.return_status === 'received_sellable' &&
                            <div className='main-info active small'>
                                <i className="fas fa-check-square" style={{color: "green"}}></i>Received in Good Condition
                            </div>
                        }
                        {order.return_status === 'received_not_sellable' &&
                            <div className='main-info active small'>
                                <i className="fas fa-window-close" style={{color: "red"}}></i>Received in Bad Condition
                            </div>
                        }


                </div>
                <div className="messages">
                    <div className="buttons">
                    {order.return_status === 'pending_reception' && <>
                        <div className='button center' onClick={() => markReturnReceived(true)}><i className="fas fa-check-square" style={{color: "green"}}></i>Mark as Received in Good Condition</div>
                        <div className='button center' onClick={() => markReturnReceived(false)}><i className="fas fa-window-close" style={{color: "red"}}></i>Mark as Received in Bad Condition</div>
                        </>
                    }
                    </div>
                    <div className="buttons">
                    {order.return_status === 'received_sellable' && !order.placed_in_inventory && <>
                        <div className='button center' onClick={openInventoryForm}><i className="fas fa-warehouse"></i>Place Product in Inventory</div>
                        </>
                    }
                    </div>
                </div>
                </>
            )}
            </>
        )}
        {View === 'messages' &&(
            <>
            <div className="messages">
            {order.messages.length === 0 && "No Messages"}
            {order.messages.length > 0 && order.messages.map((message)=> <div className={`message ${message.sender === 'Client' ? 'client' : 'response'}`}>{message.content}</div>)}
            </div>
            <div className="buttons">
            {order.needs_customer_response && (<div className='button center' onClick={markAnswered}><i className="fas fa-check-square" style={{color: "green"}}></i>No Answer Needed</div>)}
            {(<div className='button center' onClick={copyConversation}><i className="fas fa-copy"></i>Copy Conversation</div>)}
            </div>
            </>
        )}
        {View === 'finance' && (<>
            <div class="finance-infos">
            <div class='finance-info'>
            <b>Revenue</b>${order.actual_net_revenue}
            </div>
            <div class='finance-info'>
            <b>Purchase</b>${order.actual_purchase_cost}
            </div>
            <div class='finance-info'>
            <b>Shipment</b>${order.actual_shipment_cost}
            </div>
            <div class='finance-info'>
            <b>Margin</b>${order.actual_net_profit}
            </div>
            <div class='finance-info'>
            <b>Margin %</b>{order.actual_net_profit_percent}%
            </div>
            </div>
            {order.transactions.length > 0 && (
                <div className="transactions">
                <div className="table">
                    <div className="table-header">
                        <div className="type">Type</div>
                        <div className="description">Description</div>
                        <div className="settlement">Settlement</div>
                        <div className="amount">Amount</div>
                        <div className="currency">Currency</div>
                    </div>
                    <div className="table-body">
                        {order.transactions && order.transactions.length > 0 &&
                        order.transactions.map((transaction) => (
                            <TransactionLine
                            id={transaction.id}
                            type={transaction.type}
                            description={transaction.description}
                            settlement={transaction.settlement}
                            amount={transaction.amount}
                            currency={transaction.currency}
                            />
                        ))
                        }
                    </div>
                    </div></div>)}
        </>)}
        { View === 'extra' && (
        <>
            <div className='messages'>
                {order.comment &&  <div className='message comment'>{order.comment}</div>}
            <div className="buttons">
                {!order.comment &&  <button onClick={openCommentForm}>Create Comment</button>}
                {order.comment &&  <button onClick={openCommentForm}>Edit Comment</button>}
                {order.has_action && <button onClick={markActionDone}>Mark Action as Complete</button>}
            </div>
            {order.logs.length > 0 && order.logs.map((log)=> <div className='message log'>{log.event}</div>)}
            </div>
        </>
        )}
        {purchaseFormVisible && (<><PurchaseForm notify = {notify} visible={purchaseFormVisible} setVisible={setPurchaseFormVisible} order={order} setFormSubmitted={setFormSubmitted} formSubmitted={formSubmitted}/><div className="bg-disabled"></div></>)}
        {orderUpdateFormVisible && (<><OrderUpdateForm notify = {notify} visible={orderUpdateFormVisible} setVisible={setOrderUpdateFormVisible} order={order} setFormSubmitted={setFormSubmitted} formSubmitted={formSubmitted}/><div className="bg-disabled"></div></>)}
        {shipmentFormVisible && (<><ShipmentForm notify = {notify}  visible={shipmentFormVisible} setVisible={setShipmentFormVisible} purchase={order.purchases[0]} setFormSubmitted={setFormSubmitted} formSubmitted={formSubmitted} isMulti={false}/><div className="bg-disabled"></div></>)}
        {CustomerShipmentFormVisible && (<><CustomerShipmentForm notify = {notify}  visible={CustomerShipmentFormVisible} setVisible={setCustomerShipmentFormVisible} orderId={order.id} setFormSubmitted={setFormSubmitted} formSubmitted={formSubmitted}/><div className="bg-disabled"></div></>)}
        {FeedbackFormVisible && (<><FeedbackForm notify = {notify}  visible={FeedbackFormVisible} setVisible={setFeedbackFormVisible} orderId={order.id} setFormSubmitted={setFormSubmitted} formSubmitted={formSubmitted}/><div className="bg-disabled"></div></>)}
        {CommentFormVisible && (<><CommentForm notify = {notify}  visible={CommentFormVisible} setVisible={setCommentFormVisible} order={order} setFormSubmitted={setFormSubmitted} formSubmitted={formSubmitted}/><div className="bg-disabled"></div></>)}
        {inventoryFormVisible && (<><InventoryForm notify = {notify} visible={inventoryFormVisible} setVisible={setInventoryFormVisible} productId={order.products[0].product.id} orders={[order]}/><div className="bg-disabled"></div></>)}

    </>
  )
}

export default Order