import { useParams } from 'react-router-dom';
import Loading from './Loading';
import useFetch from '../api/useFetch';
import { Link } from 'react-router-dom';

const Listing = () => {
  const { id } = useParams();
  const { result: listing, isLoading: listingLoading, error: listingError } = useFetch(`/listing/${id}`, []);

  return (
    <>
      {listingLoading && <Loading />}
      {listingError && <div>{listingError}</div>}
      {listing && (
        <div className="product-infos">
          <div className="main-product-infos">
            <div className="product-image">
              <Link to={`/product/${listing.product.id}`}><img src={listing.product.image_url} alt={listing.product.name} /></Link>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Title</h2>
              <h3><Link to={`/product/${listing.product.id}`}>{listing.product.name.length > 75  ? listing.product.name.substring(0, 70).concat(" (...)") : listing.product.name}</Link></h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-barcode"></i>ASIN</h2>
              <h3>{listing.product.asin}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-barcode"></i>EAN</h2>
              <h3>{listing.product.ean}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-barcode"></i>SKU</h2>
              <h3>{listing.sku}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-barcode"></i>Price</h2>
              <h3>{parseFloat(listing.price).toFixed(2)} {listing.currency}</h3>
            </div>
            <div class="product-info-line">
                <h2><i class="fas fa-weight-hanging"></i>Weight</h2>
                <h3>{listing.product.weight * 1000} g / {Number(listing.product.weight * 2.20462).toFixed(2)} lbs</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-barcode"></i>Available</h2>
              <h3>{listing.product.available ? "Yes" : "No"}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>In Stock Martelle</h2>
              <h3>{listing.product.in_stock_martelle ? "Yes" : "No"}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-search"></i>Amazon</h2>
              <h3>
                <a href={`https://www.${listing.marketplace.domain}/dp/${listing.product.asin}/`} target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-link"></i>
                </a>
              </h3>
              </div>
            <div class="product-info-line">
              <h2><i class="fas fa-search"></i>{listing.product.sourcing_marketplace.name}</h2>
              <h3><Link to={`https://www.librairiemartelle.com/livre/${listing.product.ean}`} target="_blank"><i class="fas fa-link"></i></Link></h3>
            </div>
          </div>
          <div className="main-product-infos">
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Listing Creation Date</h2>
              <h3>{new Date(listing.created_at).toLocaleDateString()}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Listing Last Update</h2>
              <h3>{new Date(listing.updated_at).toLocaleDateString()}</h3>

            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>TTC Product Cost (EUR / USD)</h2>
              <h3>{parseFloat(listing.product.ttc_price_dilicom).toFixed(2)}€ / ${(parseFloat(listing.product.ttc_price_dilicom) / parseFloat(listing.sourcing_fx_rate)).toFixed(2)} </h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>HT Product Cost (EUR / USD)</h2>
              <h3>{(parseFloat(listing.product.ttc_price_dilicom) / 1.055 ).toFixed(2)}€ / ${(((parseFloat(listing.product.ttc_price_dilicom) / 1.055 )) / parseFloat(listing.sourcing_fx_rate)).toFixed(2)}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Product Cost with Martelle Discount (EUR / USD)</h2>
              <h3>{(parseFloat(listing.product.ttc_price_dilicom) * 0.85 / 1.055 ).toFixed(2)}€ / ${(((parseFloat(listing.product.ttc_price_dilicom) * 0.85 / 1.055 )) / parseFloat(listing.sourcing_fx_rate)).toFixed(2)}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Extra Sourcing Cost (EUR / USD)</h2>
              <h3>{parseFloat(listing.sourcing_extra_shipping_cost).toFixed(2)}€ / ${(parseFloat(listing.sourcing_extra_shipping_cost) / parseFloat(listing.sourcing_fx_rate)).toFixed(2)} </h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Total Sourcing Cost exc Shipping (EUR / USD)</h2>
              <h3>{((parseFloat(listing.product.ttc_price_dilicom) * 0.85 / 1.055 ) + (parseFloat(listing.sourcing_extra_shipping_cost))).toFixed(2)}€ / ${(((parseFloat(listing.product.ttc_price_dilicom) * 0.85 / 1.055 ) + (parseFloat(listing.sourcing_extra_shipping_cost))) / parseFloat(listing.sourcing_fx_rate)).toFixed(2)}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Sourcing Shipping cost (EUR / USD)</h2>
              <h3>{parseFloat(listing.purchase_shipping_price).toFixed(2)}€ / ${(parseFloat(listing.purchase_shipping_price) / parseFloat(listing.sourcing_fx_rate)).toFixed(2)} </h3>
            </div>
            <div className="product-info-line">
            <h2><i className="fas fa-book"></i>Total Sourcing Cost inc Shipping (EUR / USD)</h2>
            <h3>{((parseFloat(listing.product.ttc_price_dilicom) * 0.85 / 1.055 ) + (parseFloat(listing.sourcing_extra_shipping_cost)) + parseFloat(listing.purchase_shipping_price)).toFixed(2)}€ / ${(((parseFloat(listing.product.ttc_price_dilicom) * 0.85 / 1.055 ) + (parseFloat(listing.sourcing_extra_shipping_cost)) + parseFloat(listing.purchase_shipping_price)) / parseFloat(listing.sourcing_fx_rate)).toFixed(2)} </h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Workforce cost (USD)</h2>
              <h3>${parseFloat(listing.workforce_cost_usd).toFixed(2)}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Supplies Cost (USD)</h2>
              <h3>${parseFloat(listing.supplies_cost_usd).toFixed(2)}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Shipping cost (USD)</h2>
              <h3>${parseFloat(listing.shipping_cost_usd).toFixed(2)}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Margin (USD)</h2>
              <h3>${parseFloat(listing.margin_usd).toFixed(2)}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Amazon Fee (USD)</h2>
              <h3>${parseFloat(listing.marketplace_fee).toFixed(2)}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Calculated Listing Price (USD)</h2>
              <h3>${((((parseFloat(listing.product.ttc_price_dilicom) * 0.85 / 1.055 ) + (parseFloat(listing.sourcing_extra_shipping_cost)) + parseFloat(listing.purchase_shipping_price)) / parseFloat(listing.sourcing_fx_rate)) + parseFloat(listing.workforce_cost_usd) + parseFloat(listing.supplies_cost_usd) + parseFloat(listing.shipping_cost_usd) + parseFloat(listing.margin_usd) + parseFloat(listing.marketplace_fee)).toFixed(2)}</h3>
            </div>
            <br />
            <br />
            <div className="product-info-line">
            <h2><i className="fas fa-book"></i>Price Accuracy</h2>
            <h3 style={{color: (((((parseFloat(listing.product.ttc_price_dilicom) * 0.85 / 1.055 ) + (parseFloat(listing.sourcing_extra_shipping_cost)) + parseFloat(listing.purchase_shipping_price)) / parseFloat(listing.sourcing_fx_rate)) + parseFloat(listing.workforce_cost_usd) + parseFloat(listing.supplies_cost_usd) + parseFloat(listing.shipping_cost_usd) + parseFloat(listing.margin_usd) + parseFloat(listing.marketplace_fee)) - parseFloat(listing.price)) > 0.05 ? "red" : "green"}}>{(((((parseFloat(listing.product.ttc_price_dilicom) * 0.85 / 1.055 ) + (parseFloat(listing.sourcing_extra_shipping_cost)) + parseFloat(listing.purchase_shipping_price)) / parseFloat(listing.sourcing_fx_rate)) + parseFloat(listing.workforce_cost_usd) + parseFloat(listing.supplies_cost_usd) + parseFloat(listing.shipping_cost_usd) + parseFloat(listing.margin_usd) + parseFloat(listing.marketplace_fee)) - parseFloat(listing.price)).toFixed(2)}</h3>
            </div>
            <div className="product-info-line">
            <h2><i className="fas fa-book"></i>Sourcing Shipping Cost Accuracy</h2>
            <h3 style={{color: (parseFloat(listing.purchase_shipping_price) - parseFloat(listing.product.weight) * 10 ) < -0.05 ? "red" : "green"}}>{(parseFloat(listing.purchase_shipping_price) - parseFloat(listing.product.weight) * 10).toFixed(2)}</h3>
            </div>
            <br />
            <br />
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Competition Ratio</h2>
              <h3>{listing.competition_ratio || "Unknown"}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Best Price</h2>
              <h3>${listing.best_price_value}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Featured Price</h2>
              <h3>${listing.featured_price_value}</h3>
            </div>
            <div className="product-info-line">
              <h2><i className="fas fa-book"></i>Featured Competitor</h2>
              <h3>{listing.featured_price_competitor}</h3>
              </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Listing;