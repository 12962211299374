import React from 'react';
import { useContext } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import '../css/main.css';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import Order from './Order';
import Finance from './Finance';
import Orders from './Orders';
import Product from './Product';
import Metrics from './Metrics';
import SearchResults from './SearchResults';
import SidebarContext from '../context/SidebarContext';
import SearchResultsContext from '../context/SearchTermContext';
import Inventory from '../components/Inventory'
import Products from '../components/Products'
import Suppliers from './Suppliers';
import Scripts from './Scripts';
import Logo from '../static/img/epytis_logo_simplified.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Shipping from './Shipping';
import Quality from './Quality';
import Script from './Script';
import Supplier from './Supplier';
import SellingRoutes from './SellingRoutes';
import Listing from './Listing';
import Competitors from './Competitors';

const Main = () => {

    const { collapsed } = useContext(SidebarContext);
    const { setSearchResults } = useContext(SearchResultsContext);
    const clearSearchResults = () => {
        setSearchResults([]);
      };

    const notify = (text, status="success") => {
        if (status === 'success') {
        toast.success(text, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "custom-toast",
        });}
        else {
            toast.error(text, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "custom-toast",
              });
        }

      };

    return (
        <div>
            <div className={`rectangle ${collapsed ? 'collapsed' : ''}`}>
            {((window.location.hostname === 'localhost') &&
                    (<Link to ="/"><div className="logo"><img src="https://www.pngfind.com/pngs/m/170-1706361_web-development-icon-web-development-logo-png-transparent.png" alt="Development Logo" width="80px" /></div></Link>))
            ||
                (<Link to ="/"><div className="logo"><img src={Logo} alt="Epytis Logo" width="80px" /></div></Link>)
            }
            </div>
        <Topbar clearSearchResults={clearSearchResults} />
        <Sidebar />
            <div className={`main ${collapsed ? 'collapsed' : ''}`}>
                <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route path="/order/:id" element={<Order  notify={notify} />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/search" element={<SearchResults />} />
                    <Route path="/cancel_refunds" element={<Orders showFilters={false} status='cancel_refunds'/>} />
                    <Route path="/to_find" element={<Orders showFilters={false} status='pending_sourcing'/>} />
                    <Route path="/to_print" element={<Orders showFilters={false} status='to_print'/>} />
                    <Route path="/to_buy" element={<Orders showFilters={false} status='to_buy'/>} />
                    <Route path="/to_ship" element={<Orders showFilters={false} status='to_ship'/>} />
                    <Route path="/delivery" element={<Orders showFilters={false} status='to_pickup'/>} />
                    <Route path="/returns" element={<Orders showFilters={false} status='returns'/>} />
                    <Route path="/actions" element={<Orders showFilters={false} status='actions'/>} />
                    <Route path="/quality" element={<Quality notify={notify}/>} />
                    <Route path="/shipping" element={<Shipping notify={notify} />} />
                    <Route path="/product/:id" element={<Product notify={notify}/>} />
                    <Route path="/finance" element={<Finance notify={notify}/>} />
                    <Route path="/inventory" element={<Inventory />} />
                    <Route path="/metrics" element={<Metrics />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/suppliers" element={<Suppliers />} />
                    <Route path="/supplier/:id" element={<Supplier />} />
                    <Route path="/scripts" element={<Scripts notify={notify} />} />
                    <Route path="/script/:id" element={<Script notify={notify} />} />
                    <Route path="/routes" element={<SellingRoutes />} />
                    <Route path="/listing/:id" element={<Listing />} />
                    <Route path="/competitors" element={<Competitors />} />
                </Routes>
            </div>
            <ToastContainer   autoClose={2000} hideProgressBar />
        </div>
    );
};

export default Main;