import React from 'react'

const RouteLine = ({id, sourcing, destination, segment, status, algorithm, deployed, products, products_available, listings, listings_with_competition_info, best_price, featured, competition_ratio, sales_count, products_with_sales, showSubroutes, setShowSubroutes}) => {
  return (
    <div className="table-row" data-id={id}>
      {!showSubroutes &&<div className="small">{segment === 'all' && <i onClick={() => setShowSubroutes(true)} className="fas fa-plus-square"></i>}</div>}
      {showSubroutes &&<div className="small">{segment === 'all' && <i onClick={() => setShowSubroutes(false)} className="fas fa-minus-square"></i>}</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{sourcing}</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{destination}</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{segment}</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{status}</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{algorithm}</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{deployed}</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{products}</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="medium">{products_available} ({(products_available/products * 100).toFixed(1)}%)</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{listings} ({(listings/products_available * 100).toFixed(1)}%)</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{listings_with_competition_info} ({(listings_with_competition_info/listings * 100).toFixed(1)}%)</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{best_price} ({(best_price/listings_with_competition_info * 100).toFixed(1)}%)</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{featured} ({(featured/listings_with_competition_info * 100).toFixed(1)}%)</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{competition_ratio}</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{sales_count}</div>}
      {((segment === 'all' || showSubroutes)) && <div style={{fontWeight: segment === 'all' ? 'bold' : 'normal'}} className="small">{products_with_sales}</div>}
    </div>
  )
}

export default RouteLine